<template lang="html">
  <div class="component-wrapper" v-if="this.random">
      <SearchResults :searchResultsData="this.random"></SearchResults>
  </div>
</template>

<script>
import SearchResults from '@/components/search_results/SearchResults.vue'
export default {
    title: 'Random',
    components: {
        SearchResults
    },
    data() {
        return {
            random: null,
            randomInts: '',
            randomIntsList: []
        }
    },
    mounted() {
        this.getRandomInts()
        this.getRandom()
    },
    methods: {
        getRandom() {
            this.$http.get(`${this.$store.state.endpoints.baseUrl}podcasts/?id__in=${this.randomInts}&limit=100`).then((response) => {
                this.random = response.data
            })
        },
        getRandomInts(max=2000) {
            while (this.randomInts.length < 100) {
                let randInt = Math.floor(Math.random() * max).toString()
                if (!this.randomIntsList.includes(randInt) && randInt !== 0) {
                    this.randomIntsList.push(randInt)
                    this.randomInts += randInt + ','
                }
            }
        },
    }
}
</script>

<style lang="css">
</style>
